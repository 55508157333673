<template>
  <div class="hold-transition">
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Ventanas de Enturnamiento</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Hidrocarburos</li>
                  <li class="breadcrumb-item active">Procesos</li>
                  <li class="breadcrumb-item active">
                    Ventanas de Enturnamiento
                  </li>
                  <li class="breadcrumb-item active">
                    Formulario {{ accion }}
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="card">
              <div class="card-header pt-2 pb-2">
                <div class="col-6 pt-2 pb-2">
                  <h5 class="mb-0">
                    Formulario {{ accion }} Ventanas de Enturnamiento
                  </h5>
                </div>
                <ul class="nav nav-tabs" id="myTab" role="tablist">
                  <li class="nav-item">
                    <a
                      class="nav-link active"
                      id="tab-Principal"
                      data-toggle="tab"
                      href="#Principal"
                      >Principal</a
                    >
                  </li>
                  <li class="nav-item">
                    <a
                      class="nav-link"
                      id="tab-VentanasCriterios"
                      data-toggle="tab"
                      href="#VentanasCriterios"
                      v-if="form.id"
                      >Criterios</a
                    >
                  </li>
                </ul>
                <div class="tab-content" id="myTabContent">
                  <div class="tab-pane fade active show" id="Principal">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-md-4">
                          <div class="form-group">
                            <label for="nombre">Nombre</label>
                            <input
                              type="text"
                              class="form-control form-control-sm"
                              id="nombre"
                              v-model="form.nombre"
                              :class="
                                $v.form.nombre.$invalid
                                  ? 'is-invalid'
                                  : 'is-valid'
                              "
                            />
                          </div>
                        </div>
                        <div class="col-md-3">
                          <div class="form-group">
                            <label for="tipo_ventana">Clase de Viaje</label>
                            <select
                              class="form-control form-control-sm"
                              v-model="form.tipo_ventana"
                              :class="
                                $v.form.tipo_ventana.$invalid
                                  ? 'is-invalid'
                                  : 'is-valid'
                              "
                            >
                              <option value="">Seleccione...</option>
                              <option
                                v-for="tipo_ventana in listasForms.clases_viaje"
                                :key="tipo_ventana.numeracion"
                                :value="tipo_ventana.numeracion"
                              >
                                {{ tipo_ventana.descripcion }}
                              </option>
                            </select>
                            <div
                              class="error"
                              v-if="!$v.form.tipo_ventana.required"
                            >
                              Seleccione una clase
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6" v-if="form.id">
                          <div class="card-body">
                            <div
                              class="card card-secondary card-outline col-md-12"
                            >
                              <div class="card-header">
                                <h3 class="card-title">
                                  <i class="fas fa-sort-amount-down-alt"></i>
                                  Sitios
                                </h3>
                              </div>
                              <div class="card-body">
                                <div class="row">
                                  <div class="col-md-10">
                                    <div class="form-group">
                                      <label for="sitios">Sitio</label>
                                      <v-select
                                        :class="
                                          $store.getters.getDarkMode
                                            ? 'dark-vselect'
                                            : ''
                                        "
                                        v-model="sitio"
                                        label="nombre"
                                        :options="listasForms.sitios"
                                        @input="selectSitio()"
                                      ></v-select>
                                    </div>
                                  </div>
                                  <div class="col-md-2">
                                    <button
                                      class="btn btn-dark col-md-12 mt-4"
                                      v-if="form.id"
                                      @click="saveSitio()"
                                    >
                                      <i class="fas fa-save"></i>
                                    </button>
                                  </div>
                                  <div class="card-body p-0">
                                    <table
                                      id="sitios"
                                      class="table table-bordered table-striped table-hover table-sm"
                                    >
                                      <thead>
                                        <tr>
                                          <th>Sitio</th>
                                          <th>Acción</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr
                                          v-for="data in ventanasHasSitios"
                                          :key="data.id"
                                        >
                                          <td>{{ data.nombre }}</td>
                                          <td>
                                            <button
                                              type="button"
                                              class="btn btn-danger btn-sm"
                                              @click="
                                                destroySitio(
                                                  data.id,
                                                  data.pivot
                                                    .ventana_enturnamiento_id
                                                )
                                              "
                                            >
                                              <i class="fas fa-trash-alt"></i>
                                            </button>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6" v-if="form.id">
                          <div class="card-body">
                            <div
                              class="card card-secondary card-outline col-md-12"
                            >
                              <div class="card-header">
                                <h3 class="card-title">
                                  <i class="fas fa-sort-amount-down-alt"></i>
                                  Puntos de Gestión
                                </h3>
                              </div>
                              <div class="card-body">
                                <div class="row">
                                  <div class="col-md-5">
                                    <div class="form-group">
                                      <label for="puntos"
                                        >Puntos de Gestión</label
                                      >
                                      <v-select
                                        :class="
                                          $store.getters.getDarkMode
                                            ? 'dark-vselect'
                                            : ''
                                        "
                                        v-model="punto"
                                        label="nombre"
                                        :options="listasForms.puntos"
                                        @input="selectPunto()"
                                      ></v-select>
                                    </div>
                                  </div>
                                  <div class="col-md-5">
                                    <div class="form-group">
                                      <label for="lapso_llamado"
                                        >Lapso Llamado (Min)</label
                                      >
                                      <input
                                        type="number"
                                        class="form-control form-control-sm"
                                        id="lapso_llamado"
                                        v-model="formPunto.lapso_llamado"
                                        :class="
                                          $v.formPunto.lapso_llamado.$invalid
                                            ? 'is-invalid'
                                            : 'is-valid'
                                        "
                                      />
                                    </div>
                                  </div>
                                  <div class="col-md-2">
                                    <button
                                      class="btn btn-dark col-md-12 mt-4"
                                      v-if="form.id"
                                      @click="savePunto()"
                                    >
                                      <i class="fas fa-save"></i>
                                    </button>
                                  </div>
                                  <div class="card-body p-0">
                                    <table
                                      id="puntos"
                                      class="table table-bordered table-striped table-hover table-sm"
                                    >
                                      <thead>
                                        <tr>
                                          <th>Punto de gestión</th>
                                          <th>Lapso llamado</th>
                                          <th>Acción</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr
                                          v-for="data in ventanasHasPuntos"
                                          :key="data.id"
                                        >
                                          <td>{{ data.nombre }}</td>
                                          <td>
                                            {{ data.pivot.lapso_llamado }} Min
                                          </td>

                                          <td>
                                            <button
                                              type="button"
                                              class="btn btn-danger btn-sm"
                                              @click="
                                                destroyPunto(
                                                  data.id,
                                                  data.pivot
                                                    .ventana_enturnamiento_id
                                                )
                                              "
                                            >
                                              <i class="fas fa-trash-alt"></i>
                                            </button>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="tab-pane fade"
                    id="VentanasCriterios"
                    v-if="form.id"
                  >
                    <VentanasEnturnamientoCriterios
                      ref="criterios"
                      v-if="form.id"
                    />
                  </div>
                </div>
              </div>
              <div class="card-footer">
                <div class="row">
                  <button
                    class="btn bg-primary col-md-1"
                    v-show="!$v.form.$invalid"
                    @click="save()"
                  >
                    <i class="fas fa-paper-plane"></i><br />Guardar
                  </button>

                  <button class="btn bg-secondary col-md-1" @click="back()">
                    <i class="fas fa-reply"></i><br />Volver
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import vSelect from "vue-select";
import axios from "axios";
import { required, minLength, email } from "vuelidate/lib/validators";
import VentanasEnturnamientoCriterios from "./VentanasEnturnamientoCriterios.vue";

export default {
  name: "VentanasEnturnamientoForm",
  components: {
    VentanasEnturnamientoCriterios,
    vSelect,
  },
  data() {
    return {
      form: {},
      formPunto: {},
      formSitio: {},
      punto: null,
      sitio: null,
      ventanasHasPuntos: [],
      ventanasHasSitios: [],
      enturne: {},
      accion: "",
      metodo: "",
      listasForms: {
        clases_viaje: [],
        entidades: [],
        puntos: [],
        sitios: [],
      },
    };
  },

  validations: {
    form: {
      nombre: {
        required,
      },
      tipo_ventana: {
        required,
      },
    },
    formPunto: {
      punto_gestion_id: {
        required,
      },
      lapso_llamado: {
        required,
      },
    },
    formSitio: {
      sitio_id: {
        required,
      },
    },
  },
  methods: {
    getClasesViaje() {
      axios.get("/api/lista/18").then((response) => {
        this.listasForms.clases_viaje = response.data;
      });
    },

    selectPunto() {
      this.formPunto.punto_gestion_id = this.punto.id;
    },

    getPuntos() {
      axios
        .get("/api/admin/puntos_gestion/lista", {
          params: {
            estado: 1,
          },
        })
        .then((response) => {
          this.listasForms.puntos = response.data;
        });
    },

    selectSitio() {
      this.formSitio.sitio_id = this.sitio.id;
    },

    getSitios() {
      axios
        .get("/api/admin/sitios/lista", {
          params: {
            estado: 1,
          },
        })
        .then((response) => {
          this.listasForms.sitios = response.data;
        });
    },

    getVentanasHasSitiosPuntos() {
      axios
        .get("/api/hidrocarburos/ventanasEnturnamiento/" + this.form.id)
        .then((response) => {
          if (response.data[0].puntos != undefined) {
            this.ventanasHasPuntos = response.data[0].puntos;
            this.ventanasHasSitios = response.data[0].sitios;
          }
        });
    },

    save() {
      if (this.accion == "Editar") {
        let ventanasOrdenamiento = this.$refs.criterios.ventanasOrdenamiento;
        let ventanasAgrupamiento = this.$refs.criterios.ventanasAgrupamiento;
        this.form.ventanasOrdenamiento = ventanasOrdenamiento;
        this.form.ventanasAgrupamiento = ventanasAgrupamiento;
      }
      if (!this.$v.form.$invalid) {
        axios({
          method: this.metodo,
          url: "/api/hidrocarburos/ventanasEnturnamiento",
          data: this.form,
        })
          .then((response) => {
            this.form = response.data;
            this.$swal({
              icon: "success",
              title: "La ventana de enturne se guardo exitosamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            if (this.accion == "Crear") {
              this.accion = "Editar";
            }
          })
          .catch((e) => {
            this.$swal({
              icon: "error",
              title: "Ocurrio un error, vuelva a intentarlo..." + e,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    savePunto() {
      this.formPunto.ventana_enturnamiento_id = this.form.id;
      this.formPunto.accion = true;
      if (!this.$v.formPunto.$invalid) {
        axios({
          method: "PUT",
          url: "/api/hidrocarburos/ventanasEnturnamiento/assingOrRevokePunto",
          data: this.formPunto,
        })
          .then(() => {
            this.formPunto = {};
            this.punto = {};
            this.$swal({
              icon: "success",
              title: "Se guardo exitosamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            this.getVentanasHasSitiosPuntos();
          })
          .catch((e) => {
            this.$swal({
              icon: "error",
              title: "Ocurrio un error, el punto ya se encuentra asignado",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    saveSitio() {
      this.formSitio.ventana_enturnamiento_id = this.form.id;
      this.formSitio.accion = true;
      if (!this.$v.formSitio.$invalid) {
        axios({
          method: "PUT",
          url: "/api/hidrocarburos/ventanasEnturnamiento/assingOrRevokeSitio",
          data: this.formSitio,
        })
          .then(() => {
            this.formSitio = {};
            this.sitio = {};
            this.$swal({
              icon: "success",
              title: "Se guardo exitosamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            this.getVentanasHasSitiosPuntos();
          })
          .catch((e) => {
            this.$swal({
              icon: "error",
              title: "Ocurrio un error, el punto ya se encuentra asignado",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    destroyPunto(idPuntoGestion, idVentanaEnturnamiento) {
      this.$swal({
        title: "Esta seguro de eliminar este punto?",
        text: "Los cambios no se pueden revertir!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, Eliminar!",
      }).then((result) => {
        // <--
        if (result.value) {
          axios({
            method: "PUT",
            url: "/api/hidrocarburos/ventanasEnturnamiento/assingOrRevokePunto",
            data: {
              ventana_enturnamiento_id: idVentanaEnturnamiento,
              punto_gestion_id: idPuntoGestion,
              accion: false,
            },
          }).then(() => {
            this.getVentanasHasSitiosPuntos();
            this.$swal({
              icon: "success",
              title: "Se elimino el punto exitosamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 4000,
              timerProgressBar: true,
            });
          });
        }
      });
    },

    destroySitio(idSitio, idVentanaEnturnamiento) {
      this.$swal({
        title: "Esta seguro de eliminar este sitio?",
        text: "Los cambios no se pueden revertir!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, Eliminar!",
      }).then((result) => {
        // <--
        if (result.value) {
          axios({
            method: "PUT",
            url: "/api/hidrocarburos/ventanasEnturnamiento/assingOrRevokeSitio",
            data: {
              ventana_enturnamiento_id: idVentanaEnturnamiento,
              sitio_id: idSitio,
              accion: false,
            },
          }).then(() => {
            this.getVentanasHasSitiosPuntos();
            this.$swal({
              icon: "success",
              title: "Se elimino el punto exitosamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 4000,
              timerProgressBar: true,
            });
          });
        }
      });
    },

    back() {
      return this.$router.replace("/Hidrocarburos/VentanasEnturnamiento");
    },

    init() {
      this.accion = this.$route.params.accion;
      if (this.$route.params.accion == "Editar") {
        this.form = this.$route.params.data_edit;
        this.metodo = "PUT";
        this.getVentanasHasSitiosPuntos();
      } else {
        this.metodo = "POST";
        this.form.estado = 2;
        this.accion = "Crear";
      }
    },
  },

  mounted() {
    this.init();
    this.getClasesViaje();
    this.getPuntos();
    this.getSitios();
  },
};
</script>
