<template>
  <div>
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-6">
            <div class="card-body">
              <div class="card card-secondary card-outline col-md-12">
                <div class="card-header">
                  <h3 class="card-title">
                    <i class="fas fa-layer-group"></i>
                    Criterios de Agrupación
                  </h3>
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-10">
                      <div class="form-group">
                        <label for="criterio">Criterio</label>
                        <select
                          class="form-control form-control-sm"
                          v-model="formAgrupamiento.criterio"
                          :class="
                            $v.formAgrupamiento.criterio.$invalid
                              ? 'is-invalid'
                              : 'is-valid'
                          "
                        >
                          <option value="">Seleccione...</option>
                          <option
                            v-for="criterio in listasForms.criteriosAgrupamiento"
                            :key="criterio.numeracion"
                            :value="criterio.numeracion"
                          >
                            {{ criterio.descripcion }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="col-md-2">
                      <button
                        class="btn btn-dark col-md-8 mt-4"
                        v-show="!$v.formAgrupamiento.$invalid"
                        @click="cargarAgrupamiento()"
                      >
                        <i class="fas fa-check"></i>
                      </button>
                    </div>
                    <div class="card-body p-0">
                      <table
                        id="agrupamientos"
                        class="table table-bordered table-striped table-hover table-sm"
                      >
                        <thead>
                          <tr>
                            <th>Criterio</th>
                            <th>Acción</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(data, index) in ventanasAgrupamiento" :key="index">
                            <td v-text="data.criterioAgru"></td>
                            <td>
                              <button
                                type="button"
                                class="btn btn-danger btn-sm"
                                @click="deleteItem('ventanasAgrupamiento', data.id)"
                              >
                                <i class="fas fa-trash-alt"></i>
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="card-body">
              <div class="card card-secondary card-outline col-md-12">
                <div class="card-header">
                  <h3 class="card-title">
                    <i class="fas fa-sort-amount-down-alt"></i>
                    Criterios de Ordenamiento
                  </h3>
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-10">
                      <div class="form-group">
                        <label for="criterio">Criterio</label>
                        <select
                          class="form-control form-control-sm"
                          v-model="formOrdenamiento.criterio"
                          :class="
                            $v.formOrdenamiento.criterio.$invalid
                              ? 'is-invalid'
                              : 'is-valid'
                          "
                        >
                          <option value="">Seleccione...</option>
                          <option
                            v-for="criterio in listasForms.criteriosOrdenamiento"
                            :key="criterio.numeracion"
                            :value="criterio.numeracion"
                          >
                            {{ criterio.descripcion }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="col-md-2">
                      <button
                        class="btn btn-dark col-md-8 mt-4"
                        v-show="!$v.formOrdenamiento.$invalid"
                        @click="cargarOrdenamiento()"
                      >
                        <i class="fas fa-check"></i>
                      </button>
                    </div>
                    <div class="card-body p-0">
                      <table
                        id="ordenamiento"
                        class="table table-bordered table-striped table-hover table-sm"
                      >
                        <thead>
                          <tr>
                            <th>Criterio</th>
                            <th>Acción</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(data, index) in ventanasOrdenamiento" :key="index">
                            <td v-text="data.criterioOrd"></td>
                            <td>
                              <button
                                type="button"
                                class="btn btn-danger btn-sm"
                                @click="deleteItem('ventanasOrdenamiento', data.id)"
                              >
                                <i class="fas fa-trash-alt"></i>
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import axios from "axios";
import { required } from "vuelidate/lib/validators";

export default {
  name: "VentanasEnturnamientoCriterios",

  data() {
    return {
      formAgrupamiento: {},
      formOrdenamiento: {},
      listasForms: {
        criteriosAgrupamiento: [],
        criteriosOrdenamiento: [],
      },
      ventanasOrdenamiento: [],
      ventanasAgrupamiento: [],
    };
  },
  validations: {
    formAgrupamiento: {
      criterio: {
        required,
      },
    },
    formOrdenamiento: {
      criterio: {
        required,
      },
    },
  },
  methods: {
    getIndex() {
      let arregloOrden = new Array();
      let arregloAgru = new Array();
      if (this.$parent.form.ordenamiento != null) {
        for (let i = 0; i < this.$parent.form.ordenamiento.length; i++) {
          arregloOrden.push({
            id: this.$parent.form.ordenamiento[i]["criterio"],
            criterioOrd: this.$parent.form.ordenamiento[i]["nCriterio"],
          });
        }
        this.ventanasOrdenamiento = arregloOrden;
      }
      if (this.$parent.form.agrupamiento != null) {
        for (let i = 0; i < this.$parent.form.agrupamiento.length; i++) {
          arregloAgru.push({
            id: this.$parent.form.agrupamiento[i]["criterio"],
            criterioAgru: this.$parent.form.agrupamiento[i]["nCriterio"],
          });
        }
        this.ventanasAgrupamiento = arregloAgru;
      }
    },

    getCriteriosAgrupamiento() {
      axios.get("/api/lista/30").then((response) => {
        if (this.$parent.form.clase_viaje == "Cargue") {
          let crit = [];
          for (let i = 0; i < response.data.length; i++) {
            let cod = response.data[i].cod_alterno;
            if (cod.includes("C")) {
              crit.push(response.data[i]);
            }
          }
          this.listasForms.criteriosAgrupamiento = crit;
        } else {
          this.listasForms.criteriosAgrupamiento = response.data;
        }
      });
    },

    getCriteriosOrdenamiento() {
      axios.get("/api/lista/31").then((response) => {
        if (this.$parent.form.clase_viaje == "Cargue") {
          this.listasForms.criteriosOrdenamiento = [response.data[0], response.data[3]];
        } else {
          this.listasForms.criteriosOrdenamiento = response.data;
        }
      });
    },

    cargarOrdenamiento() {
      for (let i = 0; i < this.listasForms.criteriosOrdenamiento.length; i++) {
        if (
          this.listasForms.criteriosOrdenamiento[i].numeracion ==
          this.formOrdenamiento.criterio
        ) {
          if (
            this.ventanasOrdenamiento.findIndex(
              (x) => x.id === this.formOrdenamiento.criterio
            ) === -1
          ) {
            this.ventanasOrdenamiento.push({
              criterioOrd: this.listasForms.criteriosOrdenamiento[i].descripcion,
              id: this.listasForms.criteriosOrdenamiento[i].numeracion,
            });
          }
        }
      }
    },

    cargarAgrupamiento() {
      for (let i = 0; i < this.listasForms.criteriosAgrupamiento.length; i++) {
        if (
          this.listasForms.criteriosAgrupamiento[i].numeracion ==
          this.formAgrupamiento.criterio
        ) {
          if (
            this.ventanasAgrupamiento.findIndex(
              (x) => x.id === this.formAgrupamiento.criterio
            ) === -1
          ) {
            this.ventanasAgrupamiento.push({
              criterioAgru: this.listasForms.criteriosAgrupamiento[i].descripcion,
              id: this.listasForms.criteriosAgrupamiento[i].numeracion,
            });
          }
        }
      }
    },

    deleteItem(item, id) {
      switch (item) {
        case "ventanasOrdenamiento":
          this.ventanasOrdenamiento = this.ventanasOrdenamiento.filter(
            (item) => item.id !== id
          );
          break;
        case "ventanasAgrupamiento":
          this.ventanasAgrupamiento = this.ventanasAgrupamiento.filter(
            (item) => item.id !== id
          );
          break;
      }
    },
  },
  mounted() {
    this.getCriteriosAgrupamiento();
    this.getCriteriosOrdenamiento();
    this.getIndex();
  },
};
</script>
