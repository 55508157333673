var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"hold-transition"},[_c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"content-wrapper"},[_c('section',{staticClass:"content-header"},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[_vm._m(0),_c('div',{staticClass:"col-sm-6"},[_c('ol',{staticClass:"breadcrumb float-sm-right"},[_c('li',{staticClass:"breadcrumb-item"},[_c('router-link',{attrs:{"to":"/"}},[_vm._v("Home")])],1),_c('li',{staticClass:"breadcrumb-item active"},[_vm._v("Hidrocarburos")]),_c('li',{staticClass:"breadcrumb-item active"},[_vm._v("Procesos")]),_c('li',{staticClass:"breadcrumb-item active"},[_vm._v(" Ventanas de Enturnamiento ")]),_c('li',{staticClass:"breadcrumb-item active"},[_vm._v(" Formulario "+_vm._s(_vm.accion)+" ")])])])])])]),_c('section',{staticClass:"content"},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header pt-2 pb-2"},[_c('div',{staticClass:"col-6 pt-2 pb-2"},[_c('h5',{staticClass:"mb-0"},[_vm._v(" Formulario "+_vm._s(_vm.accion)+" Ventanas de Enturnamiento ")])]),_c('ul',{staticClass:"nav nav-tabs",attrs:{"id":"myTab","role":"tablist"}},[_vm._m(1),_c('li',{staticClass:"nav-item"},[(_vm.form.id)?_c('a',{staticClass:"nav-link",attrs:{"id":"tab-VentanasCriterios","data-toggle":"tab","href":"#VentanasCriterios"}},[_vm._v("Criterios")]):_vm._e()])]),_c('div',{staticClass:"tab-content",attrs:{"id":"myTabContent"}},[_c('div',{staticClass:"tab-pane fade active show",attrs:{"id":"Principal"}},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"nombre"}},[_vm._v("Nombre")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.nombre),expression:"form.nombre"}],staticClass:"form-control form-control-sm",class:_vm.$v.form.nombre.$invalid
                                ? 'is-invalid'
                                : 'is-valid',attrs:{"type":"text","id":"nombre"},domProps:{"value":(_vm.form.nombre)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "nombre", $event.target.value)}}})])]),_c('div',{staticClass:"col-md-3"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"tipo_ventana"}},[_vm._v("Clase de Viaje")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.tipo_ventana),expression:"form.tipo_ventana"}],staticClass:"form-control form-control-sm",class:_vm.$v.form.tipo_ventana.$invalid
                                ? 'is-invalid'
                                : 'is-valid',on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.form, "tipo_ventana", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":""}},[_vm._v("Seleccione...")]),_vm._l((_vm.listasForms.clases_viaje),function(tipo_ventana){return _c('option',{key:tipo_ventana.numeracion,domProps:{"value":tipo_ventana.numeracion}},[_vm._v(" "+_vm._s(tipo_ventana.descripcion)+" ")])})],2),(!_vm.$v.form.tipo_ventana.required)?_c('div',{staticClass:"error"},[_vm._v(" Seleccione una clase ")]):_vm._e()])])]),_c('div',{staticClass:"row"},[(_vm.form.id)?_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"card card-secondary card-outline col-md-12"},[_vm._m(2),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-10"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"sitios"}},[_vm._v("Sitio")]),_c('v-select',{class:_vm.$store.getters.getDarkMode
                                          ? 'dark-vselect'
                                          : '',attrs:{"label":"nombre","options":_vm.listasForms.sitios},on:{"input":function($event){return _vm.selectSitio()}},model:{value:(_vm.sitio),callback:function ($$v) {_vm.sitio=$$v},expression:"sitio"}})],1)]),_c('div',{staticClass:"col-md-2"},[(_vm.form.id)?_c('button',{staticClass:"btn btn-dark col-md-12 mt-4",on:{"click":function($event){return _vm.saveSitio()}}},[_c('i',{staticClass:"fas fa-save"})]):_vm._e()]),_c('div',{staticClass:"card-body p-0"},[_c('table',{staticClass:"table table-bordered table-striped table-hover table-sm",attrs:{"id":"sitios"}},[_vm._m(3),_c('tbody',_vm._l((_vm.ventanasHasSitios),function(data){return _c('tr',{key:data.id},[_c('td',[_vm._v(_vm._s(data.nombre))]),_c('td',[_c('button',{staticClass:"btn btn-danger btn-sm",attrs:{"type":"button"},on:{"click":function($event){return _vm.destroySitio(
                                                data.id,
                                                data.pivot
                                                  .ventana_enturnamiento_id
                                              )}}},[_c('i',{staticClass:"fas fa-trash-alt"})])])])}),0)])])])])])])]):_vm._e(),(_vm.form.id)?_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"card card-secondary card-outline col-md-12"},[_vm._m(4),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-5"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"puntos"}},[_vm._v("Puntos de Gestión")]),_c('v-select',{class:_vm.$store.getters.getDarkMode
                                          ? 'dark-vselect'
                                          : '',attrs:{"label":"nombre","options":_vm.listasForms.puntos},on:{"input":function($event){return _vm.selectPunto()}},model:{value:(_vm.punto),callback:function ($$v) {_vm.punto=$$v},expression:"punto"}})],1)]),_c('div',{staticClass:"col-md-5"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"lapso_llamado"}},[_vm._v("Lapso Llamado (Min)")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formPunto.lapso_llamado),expression:"formPunto.lapso_llamado"}],staticClass:"form-control form-control-sm",class:_vm.$v.formPunto.lapso_llamado.$invalid
                                          ? 'is-invalid'
                                          : 'is-valid',attrs:{"type":"number","id":"lapso_llamado"},domProps:{"value":(_vm.formPunto.lapso_llamado)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formPunto, "lapso_llamado", $event.target.value)}}})])]),_c('div',{staticClass:"col-md-2"},[(_vm.form.id)?_c('button',{staticClass:"btn btn-dark col-md-12 mt-4",on:{"click":function($event){return _vm.savePunto()}}},[_c('i',{staticClass:"fas fa-save"})]):_vm._e()]),_c('div',{staticClass:"card-body p-0"},[_c('table',{staticClass:"table table-bordered table-striped table-hover table-sm",attrs:{"id":"puntos"}},[_vm._m(5),_c('tbody',_vm._l((_vm.ventanasHasPuntos),function(data){return _c('tr',{key:data.id},[_c('td',[_vm._v(_vm._s(data.nombre))]),_c('td',[_vm._v(" "+_vm._s(data.pivot.lapso_llamado)+" Min ")]),_c('td',[_c('button',{staticClass:"btn btn-danger btn-sm",attrs:{"type":"button"},on:{"click":function($event){return _vm.destroyPunto(
                                                data.id,
                                                data.pivot
                                                  .ventana_enturnamiento_id
                                              )}}},[_c('i',{staticClass:"fas fa-trash-alt"})])])])}),0)])])])])])])]):_vm._e()])])]),(_vm.form.id)?_c('div',{staticClass:"tab-pane fade",attrs:{"id":"VentanasCriterios"}},[(_vm.form.id)?_c('VentanasEnturnamientoCriterios',{ref:"criterios"}):_vm._e()],1):_vm._e()])]),_c('div',{staticClass:"card-footer"},[_c('div',{staticClass:"row"},[_c('button',{directives:[{name:"show",rawName:"v-show",value:(!_vm.$v.form.$invalid),expression:"!$v.form.$invalid"}],staticClass:"btn bg-primary col-md-1",on:{"click":function($event){return _vm.save()}}},[_c('i',{staticClass:"fas fa-paper-plane"}),_c('br'),_vm._v("Guardar ")]),_c('button',{staticClass:"btn bg-secondary col-md-1",on:{"click":function($event){return _vm.back()}}},[_c('i',{staticClass:"fas fa-reply"}),_c('br'),_vm._v("Volver ")])])])])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-sm-6"},[_c('h5',{staticClass:"mb-0"},[_vm._v("Ventanas de Enturnamiento")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link active",attrs:{"id":"tab-Principal","data-toggle":"tab","href":"#Principal"}},[_vm._v("Principal")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-header"},[_c('h3',{staticClass:"card-title"},[_c('i',{staticClass:"fas fa-sort-amount-down-alt"}),_vm._v(" Sitios ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',[_vm._v("Sitio")]),_c('th',[_vm._v("Acción")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-header"},[_c('h3',{staticClass:"card-title"},[_c('i',{staticClass:"fas fa-sort-amount-down-alt"}),_vm._v(" Puntos de Gestión ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',[_vm._v("Punto de gestión")]),_c('th',[_vm._v("Lapso llamado")]),_c('th',[_vm._v("Acción")])])])
}]

export { render, staticRenderFns }